<template>
  <v-container class="fill-height">
    <v-sheet
      elevation="12"
      max-width="600"
      rounded="xl"
      width="100%"
      class="pa-4 text-center ma-auto"
    >
      <v-icon
        class="mb-5"
        color="warning"
        icon="mdi-cash-clock"
        size="112"
      ></v-icon>

      <h2 class="text-h5 mb-6">Rate confirmation</h2>

      <p class="mb-4 text-medium-emphasis text-body-2">
        Insert one or more TMPs separated by a comma
      </p>

      <v-form ref="form">
        <v-text-field
          label="TMPs"
          variant="solo"
          v-model="tmps"
          :disabled="isLoading"
          :rules="rules"
        ></v-text-field>
      </v-form>

      <v-divider class="mb-4"></v-divider>

      <div class="text-end">
        <v-btn
          class="text-none text-white"
          color="secondary"
          rounded
          variant="flat"
          :loading="isLoading"
          :disabled="isDisabled || isLoading"
          @click="sendTmps"
        >
          Send
        </v-btn>
      </div>
    </v-sheet>
    <v-snackbar
      v-model="snackbar.active"
      :timeout="snackbar.timeout"
      left
      elevation="24"
      :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:actions>
        <v-btn color="red" variant="text" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import endpoints from "../utils/endpoints";

export default {
  name: "ApplicationContent",

  data: () => ({
    tmps: "",
    isLoading: false,
    snackbar: {
      active: false,
      text: "Message",
      timeout: 4000,
      color: "success",
    },
    rules: [
      (v) => !!v || "At least one TMP is required",
      (v) => !!/^[0-9,\s]+$/g.test(v) || "Only numbers and commas are allowed",
    ],
  }),

  computed: {
    isDisabled() {
      return !this.tmps && /^[0-9,\s]+$/g.test(this.tmps);
    },
  },

  methods: {
    async sendTmps() {
      this.isLoading = true;
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        const tmps = this.tmps.split(",").map((tmp) => tmp.trim());
        const tmpsObj = {
          tmps: tmps
            .map((str) => parseInt(str))
            .filter((num) => !isNaN(num) && num !== null && num !== ""),
        };

        await axios
          .post(endpoints.sendRateConfirmation(), tmpsObj)
          .then((res) => {
            this.isLoading = false;
            this.$refs.form.reset();
            console.log(res);
            this.showSnackbar("Rate confirmation sended", "success");
          })
          .catch((err) => {
            this.isLoading = false;
            this.$refs.form.reset();
            this.showSnackbar(err.message, "error");
          });
      } else {
        this.isLoading = false;
      }
    },

    showSnackbar(message, status) {
      this.snackbar.active = true;
      this.snackbar.text = message;
      this.snackbar.color = status === "success" ? "success" : "error";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
